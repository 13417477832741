
import { Vue, prop, Options } from "vue-class-component";
import { MainAppController } from "@/app/ui/controllers/MainAppController";

class Props {
  modelValue = prop<boolean>({
    type: Boolean,
    default: false
  });
  iconLeft = prop<string>({
    default: "",
    type: String
  });
  message = prop<string>({
    type: String,
    default: ""
  });
  isPositive = prop<boolean>({
    type: Boolean,
    default: true
  });
  timeout = prop<number>({
    type: Number,
    default: 0
  });
  autoClose = prop<boolean>({
    type: Boolean,
    default: false
  });
}

@Options({
  emits: ["update:modelValue", "onClose"],
  watch: {
    $route() {
      this.closeSnackbar();
    },
    modelValue() {
      if (this.modelValue) {
        if (this.timeout > 0 || this.autoClose) {
          setTimeout(
            () => {
              this.closeSnackbar();
            },
            this.autoClose ? 2000 : this.timeout
          );
        }
      }
    }
  }
})
export default class Snackbar extends Vue.with(Props) {
  closeSnackbar() {
    MainAppController.setSnackbarTimeout(0);
    MainAppController.setSnackbarPositivity(true);
    this.$emit("onClose");
    this.$emit("update:modelValue", false);
  }
}
